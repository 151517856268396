#result_wrapper {
  width: 100%;
  max-width: 940px;
  padding: 0 20px;
  margin: 50px auto;

  .inner_max {
    padding: 30px 40px 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: $box-shadow;

    .result_content {
      width: 100%;
      max-width: 960px;
      margin: 0 auto;
      text-align: center;
      color: $primary-purple;

      img {
        width: 80px;
        margin-bottom: 40px;
      }

      p {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 70px;
      }

      li {
        width: 40%;

        > img {
          width: 300px;
        }
      }

      .purple_button {
        line-height: 1.5;
      }
    }
  }
}

@media (max-width: 550px) {
  .wrapper .content_box {
    padding-top: 0;
  }

  #result_wrapper .inner_max {
    padding: 24px;

    .result_content {
      img {
        width: 61px;
      }

      p {
        font-size: 14px;
        margin-bottom: 40px;
      }
    }
  }
}
